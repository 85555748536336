const likeComment = (userEmail, postId, commentId, alreadyLiked, setLikedComments, setCommentLikesCount) => {
    //console.log('likeComment is invoked with:', userEmail, postId, commentId, alreadyLiked);  // Add console log
    if (!userEmail) {
        console.error('User or user email is not defined:', userEmail);
        return;
    }

    const endpoint = alreadyLiked
        ? '/.netlify/functions/unlikeComment'
        : '/.netlify/functions/likeComment';

    //console.log('Endpoint:', endpoint, 'User email:', userEmail, 'Already liked:', alreadyLiked);

    return fetch(endpoint, {  // make sure to return this fetch call so that it returns a Promise
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: userEmail, postId, commentId }),
    })
        .then((response) => {
            //console.log('Response after liking/unliking:', response);  // Add console log
            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            //console.log('Response data after liking/unliking:', data);  // Add console log
            setLikedComments(prevState => {
                const newState = {...prevState, [commentId]: !alreadyLiked};
                //console.log('New state:', newState);
                return newState;
            });

            // Call your backend function to get the new likes count
            return fetch('/.netlify/functions/countCommentLikes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ commentId }),
            });
        })
        .then((response) => {
            //console.log('Response after counting likes:', response);  // Add console log
            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            // Update the comment likes count
            //console.log('Data after counting likes:', data);  // Add console log
            setCommentLikesCount(data.likesCount);
        })
        .catch((error) => {
            console.error('Error updating like status:', error);
        });
};

export default likeComment;
