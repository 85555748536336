import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const StyledCardMedia = styled(CardMedia)({
    // height: 0,
    // paddingTop: '100%', // This maintains the aspect ratio of the container
    paddingTop: '100%',
    height: '100%',
    objectFit: 'cover' // This makes sure the image fits well within the container
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const TruncatedTypography = styled(Typography)({
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block', // This is important to make the link occupy the full space of the Card
});

const ProductPost = ({ post }) => (
    <StyledLink to={`/productPostProfile/${post.slug}`}>
        <Card
            sx={{
                maxWidth: '100%',
                height: '360px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                backgroundColor: '#f2f2f2',
                borderRadius: '15px',
                border: '2px solid #FFFFFF00',
                overflow: 'hidden'
            }}>
            {post.fileUrl && post.fileUrl.endsWith('.mp4') ? (
                <StyledCardVideo
                    controls
                    sx={{
                        objectFit: 'cover'
                    }}
                >
                    <source src={post.fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </StyledCardVideo>
            ) : (
                <StyledCardMedia
                    image={post.fileUrl ? post.fileUrl : "https://via.placeholder.com/640x360"}
                    title="ProductPost image"
                />
            )}
            <CardContent>
                <TruncatedTypography
                    variant="body2"
                    color="#333333">
                    {/*{post.caption}*/}
                </TruncatedTypography>
            </CardContent>
        </Card>
    </StyledLink>
);

export default ProductPost;
