/* eslint-disable no-unused-vars */
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledDialogTitle = styled(DialogTitle)({
    textAlign: 'center',
    '& .MuiTypography-root': {
        fontSize: '1.2rem',
        fontWeight: '600',
        color: '#333333',
    },
});

const StyledButton = styled(Button)({
    display: 'block',
    width: '100%',
    margin: '10px 0',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#333333',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',  // default hover effect for MUI buttons
    }
});

const AboutEvents = ({ open, onClose}) => {
    const { user, isAuthenticated } = useAuth0();
    const [petOwnerProfile, setPetOwnerProfile] = useState({
        name: '',
        bio: '',
        fileUrl: '',
    });
    //const [petDetails, setPetDetails] = useState(null);
    const [publicFigureDetails, setPublicFigureDetails] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPetOwnerProfile = async () => {
            if (isAuthenticated && user) {
                try {
                    const response = await fetch('/.netlify/functions/getPetOwnerProfile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const petOwnerProfileData = await response.json();

                    setPetOwnerProfile(petOwnerProfileData);
                } catch (error) {
                    console.error('Error fetching pet owner profile:', error);
                }
            }
        };

        fetchPetOwnerProfile();
    }, [user, isAuthenticated]);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth='sm'
            PaperProps={{
                sx: {
                    backgroundColor: '#f2f2f2',
                    border: '2px solid #FFFFFF00',
                    borderRadius: '20px',
                }
            }}
        >
            {showDetails ? (
                <>
                    <StyledDialogTitle>
                        About This Public Figure
                    </StyledDialogTitle>
                    <DialogContent>
                        {/* Using the sx prop to set the color for Typography */}
                        <Typography variant="subtitle1" sx={{ color: '#333333' }}>
                            <strong>Name:</strong> {publicFigureDetails.name}
                        </Typography>
                        {/* ... [Rest of the Typography components with the same color styling] */}
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        <StyledButton
                            variant="text"
                            onClick={() => navigate(`/eventform/`)}
                            sx={{ color:'#333333' }}
                        >
                            Create an Event
                        </StyledButton>
                    </DialogContent>
                </>
            )}
            <DialogActions>
                <StyledButton
                    variant="text"
                    onClick={onClose}
                    sx={{ color:'#333333' }}
                >
                    Close
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

export default AboutEvents;
