/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, CardMedia, CardContent, CardHeader, CardActions, Typography, Container, CircularProgress, Box, IconButton, Grid } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AboutPet from '../More/AboutPet'
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import SaveIcon from '@mui/icons-material/Save';
import {PersonAdd} from "@mui/icons-material";
import likePost from '../../pages/likePost';
import savePost from '../../pages/savePost';
import handleFollowUser from '../../pages/handleFollowUser';
import ShareForm from '../Sharing/ShareForm';
import { handleSharePost } from '../../pages/handleSharePost';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAuth0 } from '@auth0/auth0-react';
import likeComment from "../../pages/likeComment";
import Comments from '../Comments/Comments';
import Post from '../Posts/Post';
import countLikes from "../../pages/countLikes";
import ReactPlayer from 'react-player';
import likePostHome from "../../pages/likePostHome";
import {
    FacebookIcon,
    FacebookShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterShareButton,
    XIcon
} from "react-share";
import './shareButtons.css'
import Tooltip from '@mui/material/Tooltip';
import { keyframes } from '@mui/system';
import {Helmet} from "react-helmet";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledCardVideo = styled('video')({
    width: '100%',  // This makes sure the width is consistent
    height: '450px',  // Fixed height
    objectFit: 'cover',  // Ensures the video covers the area without distortion
    overflow: 'hidden',  // Hides any part of the video that might overflow
});

const TruncatedTypography = styled(Typography)({
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '1em auto', // for centering and spacing
    // border: '2px solid #FFFFFF00'
});

function PostProfile() {
    const { user, isAuthenticated } = useAuth0();
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [postIdentifier, setPostIdentifier] = useState(null);
    const [pet, setPet] = useState(null);
    const [petOwner, setPetOwner] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPostId, setCurrentPostId] = useState(null);
    const [currentSlug, setCurrentSlug] = useState(null);
    const [currentPet, setCurrentPet] = useState(null);
    const [aboutPetOpen, setAboutPetOpen] = useState(false);
    const [likedPosts, setLikedPosts] = useState({});
    const [savedPosts, setSavedPosts] = useState({});
    const [commentsOpen, setCommentsOpen] = useState(false);
    const [likedComments, setLikedComments] = useState({});
    const [followedUsers, setFollowedUsers] = useState({});
    const [shareFormOpen, setShareFormOpen] = useState(false);
    const [currentPostToShare, setCurrentPostToShare] = useState(null);
    const [likeCounts, setLikeCounts] = useState({});
    const [recommendedPosts, setRecommendedPosts] = useState([]);
    const [likesCount, setLikesCount] = useState(0);



    const iconButtonSx = {
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(2.0)',
        },
        '& .MuiSvgIcon-root': { // Target the icon inside the IconButton
            transition: 'color 0.3s ease-in-out',
        },
        '&:hover .MuiSvgIcon-root': {
            color: '#68FF00',
        },
    };

    const fetchLikesCount = async () => {
        try {
            const response = await countLikes(postIdentifier);
            setLikesCount(response);
        } catch (error) {
            console.error('Failed to fetch likes count:', error);
        }
    };

    const handleAboutPet = (pet, id, slug) => {
        setCurrentPet(pet);
        setCurrentPostId(id);
        setCurrentSlug(slug);
        setAboutPetOpen(true);
    };

    const openComments = (postId) => {
        setCurrentPostId(postId);
        setCommentsOpen(true);
    };
    const closeComments = () => {
        setCurrentPostId(null);
        setCommentsOpen(false);
    };
    const handleLikeComment = (postId, commentId) => {
        likeComment(user.email, postId, commentId, setLikedComments);
    };

    const handlePostSharing = (shareToEmail) => {
        handleSharePost(shareToEmail, user, currentPostToShare);
    };


    useEffect(() => {
        const fetchPostPetOwnerAndLikeStatus = async () => {
            try {
                const postResponse = await axios.post('/.netlify/functions/getPostProfile', { slug });
                setPostIdentifier(postResponse.data.id)
                setPost(postResponse.data);

                const petResponse = await axios.post('/.netlify/functions/getPetByPostId', { slug });
                setPet(petResponse.data);

                const petOwnerResponse = await axios.post('/.netlify/functions/getPetOwnerByPostId', { slug });

                setPetOwner(petOwnerResponse.data);

                if (isAuthenticated) {
                    const likedPostsResponse = await axios.post('/.netlify/functions/getLikedPosts', { userEmail: user.email });
                    const likedPostIds = new Set(likedPostsResponse.data);

                    setLikedPosts(prevState => ({ ...prevState, [postIdentifier]: likedPostIds.has(postIdentifier) }));


                    const savedPostsResponse = await axios.post('/.netlify/functions/getSavedPosts', { userEmail: user.email });
                    const savedPostIds = new Set(savedPostsResponse.data);
                    setSavedPosts(prevState => ({ ...prevState, [postIdentifier]: savedPostIds.has(postIdentifier) }));

                    const followResponse = await axios.post('/.netlify/functions/getFollowingOwners', { userEmail: user.email });
                    let followObj = {};
                    followResponse.data.forEach(user => {
                        followObj[user.email] = true;
                    });
                    setFollowedUsers(followObj);
                }

                // Fetch likes count here
                fetchLikesCount();

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching post profile:', error);
                setIsLoading(false);
            }
        };

        fetchPostPetOwnerAndLikeStatus();
    }, [slug, isAuthenticated, user]);

    useEffect(() => {
        const fetchPostRecommendations = async () => {
            try {
                const response = await axios.post('/.netlify/functions/getPostRecommendations', { slug });
                setRecommendedPosts(response.data);
            } catch (error) {
                console.error('Error fetching post recommendations:', error);
            }
        };

        fetchPostRecommendations();
    }, [slug]);

    // A function to create markup
    const createMarkup = htmlContent => {
        return {__html: htmlContent};
    };

    if (!post) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
                <CircularProgress />
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
                <CircularProgress />
            </Box>
        );
    }



    return (
        <Container maxWidth="md">
            <Helmet>
                <title>{post.caption ? `${post.caption} - Wagzters` : 'Post - Wagzters'}</title>
                <link rel="canonical" href={`https://wagzters.com/postprofile/${slug}`} />
                <meta
                    name="description"
                    content={post.captionLong ? `${post.captionLong.substring(0, 150)}...` : "Explore posts on Wagzters."}
                />
                <link rel="alternate" hrefLang="en" href={`https://wagzters.com/postprofile/${slug}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://wagzters.com/postprofile/${slug}`} />
            </Helmet>
            <StyledCard
                sx={{
                    border: '2px solid #FFFFFF00'
                }}
            >
                <CardHeader
                    action={
                        <IconButton onClick={() => handleAboutPet(pet, postIdentifier, slug)}>
                            <MoreVertIcon style={{ color: '#e91e63' }}/>
                        </IconButton>
                    }
                    sx={{
                        '& .MuiCardHeader-title': {  // <-- this targets the title
                            color: '#16cc7d'
                        },
                        '& .MuiCardHeader-subheader': {  // <-- this targets the subheader
                            color: '#9ca3af'
                        }
                    }}
                    title={`${pet ? pet.name : 'Unknown'}`}
                    subheader={`by: ${petOwner ? petOwner.name : 'Unknown'}`}
                />

                {post.fileUrl && post.fileUrl.endsWith('.mp4') ? (
                    <StyledCardVideo controls>
                        <source src={post.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledCardVideo>
                ) : (
                    <StyledCardMedia
                        image={post.fileUrl ? post.fileUrl : "https://via.placeholder.com/640x360"}
                        title="Post image"
                    />
                )}

                <CardActions
                    disableSpacing
                    sx={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}
                >
                    {/* Add to favorites button */}
                    <Tooltip title="Add to favorites">
                        <IconButton
                            aria-label="add to favorites"
                            onClick={() => likePostHome(user, post.id, likedPosts[post.id], setLikedPosts, setLikeCounts)}
                            sx={iconButtonSx}
                        >
                            {likedPosts[post.id] ? <FavoriteIcon sx={{ color: likedPosts[post.id] ? '#e91e63' : '#607d8b' }}/> : <FavoriteBorderIcon sx={{ color: '#607d8b' }}/>}
                        </IconButton>
                    </Tooltip>

                    {/* Comment button */}
                    <Tooltip title="Comment">
                        <IconButton
                            aria-label="comment"
                            onClick={() => openComments(post.id)}
                            sx={iconButtonSx}
                        >
                            <CommentIcon sx={{ color: '#607d8b' }}/>
                        </IconButton>
                    </Tooltip>

                    {/* Share button */}
                    <Tooltip title="Share">
                        <IconButton
                            aria-label="share"
                            onClick={() => {setCurrentPostToShare(post.id); setShareFormOpen(true);}}
                            sx={iconButtonSx}
                        >
                            <ShareIcon sx={{ color: '#607d8b' }}/>
                        </IconButton>
                    </Tooltip>

                    {/* Save button */}
                    <Tooltip title="Save">
                        <IconButton
                            aria-label="save"
                            onClick={() => savePost(user, post.id, savedPosts[post.id], setSavedPosts)}
                            sx={iconButtonSx}
                        >
                            {savedPosts[post.id] ? <SaveIcon sx={{ color: '#15a2fa' }}/> : <SaveIcon sx={{ color: '#607d8b' }}/>}
                        </IconButton>
                    </Tooltip>

                    {/* Follow button */}
                    <Tooltip title="Follow">
                        <IconButton
                            aria-label="follow"
                            onClick={() => handleFollowUser(petOwner.email, user, followedUsers, setFollowedUsers)}
                            sx={{
                                ...iconButtonSx,
                                marginRight: '8px', // Add marginRight specifically for this button
                            }}
                        >
                            {followedUsers[petOwner.email] ? <PersonAdd sx={{ color: '#15a2fa' }}/> : <PersonAdd sx={{ color: '#607d8b' }}/>}
                        </IconButton>

                    </Tooltip>

                    <PinterestShareButton
                        url={`https://wagzters.com/postprofile/${post.id}`}
                        media={post.fileUrl ? post.fileUrl : "https://via.placeholder.com/640x360"}
                        description={"Check out this post on Wagzters!"}
                        className="shareButton"
                        style={{ marginRight: '16px' }}
                    >
                        <PinterestIcon size={32} round />
                    </PinterestShareButton>

                    <TwitterShareButton
                        url={`https://wagzters.com/postprofile/${post.id}`}
                        title={"Check out this post on Wagzters!"}
                        hashtags={["Wagzters", "Pets"]}
                        className="shareButton"
                        style={{ marginRight: '16px' }}
                    >
                        <XIcon size={32} round />
                    </TwitterShareButton>

                    <FacebookShareButton
                        url={`https://wagzters.com/postprofile/${post.id}`}
                        quote={"Check out this post on Wagzters!"}
                        hashtag={"#Wagzters"}
                        className="shareButton"
                        style={{ marginRight: '16px' }}  // Add spacing between the buttons
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </CardActions>

            </StyledCard>
            {/*<div className="markdown-container" style={{*/}
            {/*    backgroundColor:'#f2f2f2',*/}
            {/*    padding:'15px',*/}
            {/*    borderRadius:'15px',*/}
            {/*    marginBottom:'15px',*/}
            {/*    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',*/}
            {/*    textAlign:'left',*/}
            {/*    color:'#4b5360'*/}
            {/*}}>*/}
            {/*    <CardContent>*/}
            {/*        <Typography*/}
            {/*            variant="h5"*/}
            {/*            sx={{*/}
            {/*                color:'#333333'*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {post.caption}*/}
            {/*        </Typography>*/}
            {/*    </CardContent>*/}
            {/*</div>*/}

            <div
                className="markdown-container"
                style={{
                    backgroundColor: '#f2f2f2',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '15px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'left',
                    color: '#4b5360',
                }}
                dangerouslySetInnerHTML={createMarkup(post.captionLong)}
            />

            <Typography
                variant="h6"
                component="div" style={{
                marginTop: '1em',
                color:'#333333'

            }}>
                Suggested Posts
            </Typography>

            <Grid container spacing={2}>
                {recommendedPosts.map(recommendedPost => (
                    <Grid item xs={12} sm={6} md={4} key={recommendedPost.id} style={{ minHeight: '500px' }}>
                        <StyledCard>
                            {/* Render the Post component with the recommended post */}
                            <Post post={recommendedPost} />
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>

            {commentsOpen && user && (
                <Comments
                    postId={currentPostId}
                    userEmail={user.email}
                    userPicture={user.picture}
                    closeComments={closeComments}
                    likeComment={handleLikeComment}
                    likedComments={likedComments}
                    commentsOpen={commentsOpen}
                    handleClose={closeComments}
                />
            )}

            {user && (
                <ShareForm
                    open={shareFormOpen}
                    onClose={() => setShareFormOpen(false)}
                    onShare={handlePostSharing}
                />
            )}

            {aboutPetOpen && currentPet && (
                <AboutPet
                    open={aboutPetOpen}
                    onClose={() => setAboutPetOpen(false)}
                    pet={currentPet}
                    post={currentPostId}
                    slug={currentSlug}
                />
            )}

        </Container>
    );
}

export default PostProfile;

