const fetchLikedComments = async (userEmail, setLikedComments) => {
    const likedResponse = await fetch('/.netlify/functions/getLikedComments', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: userEmail }),
    });

    if (!likedResponse.ok) {
        throw new Error(`HTTP error ${likedResponse.status}`);
    }

    const likedData = await likedResponse.json();

    // set the liked comments state
    let likedCommentsObj = {};
    likedData.forEach(comment => {
        likedCommentsObj[comment.id] = true;
    });
    setLikedComments(likedCommentsObj);
}

export default fetchLikedComments;
