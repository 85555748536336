/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import '../bootstrap-5.2.3-dist/css/bootstrap.min.css';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, Container, TextField, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Chip } from '@mui/material';
import { WithContext as ReactTags } from 'react-tag-input';
import UploadComponent from './UploadComponent';
import {Helmet} from "react-helmet"; // Remember to import UploadComponent here
import CryptoJS from 'crypto-js';

const CreatePost = () => {
    const { user } = useAuth0();
    const currentUserEmail = user.email;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
    const [owners, setOwners] = useState([]);
    const [pets, setPets] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState('');
    const [selectedPet, setSelectedPet] = useState('');
    const [caption, setCaption] = useState('');
    const [captionLong, setCaptionLong] = useState('');
    const [slug, setSlug] = useState('');
    const [fileUrl, setFileUrl] = useState(null);  // Add a state for the file URL
    const [isUploading, setIsUploading] = useState(false);  // Add this line
    const [tags, setTags] = useState([]);
    const [chipData, setChipData] = useState([]);

    useEffect(() => {
        fetchOwners();
    }, []);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const chipColors = ['primary', 'secondary', 'success', 'error', 'warning', 'info'];

    const handleAddChip = (event) => {
        if (event.key === 'Enter') {
            setChipData([...chipData, { key: chipData.length, label: event.target.value, color: chipColors[chipData.length % chipColors.length] }]);
            event.target.value = '';
        }
    };

    const fetchOwners = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/.netlify/functions/listPetOwners`);
            const hashedCurrentUserEmail = CryptoJS.SHA256(currentUserEmail).toString();
            const filteredOwners = response.data.filter(owner => owner.hashEmail === hashedCurrentUserEmail);
            setOwners(filteredOwners);
        } catch (error) {
            console.error('Error fetching pet owners', error);
        }
    };

    const fetchPets = async (ownerId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/.netlify/functions/listPetsByOwner/${ownerId}`);
            setPets(response.data);
        } catch (error) {
            console.error('Error fetching pets by owner', error.response.data);
        }
    };

    const handleOwnerChange = (e) => {
        setSelectedOwner(e.target.value);
        if (e.target.value) {
            fetchPets(e.target.value);
        } else {
            setPets([]);
        }
    };

    const handleCreatePost = async () => {
        if (!selectedOwner || !selectedPet) {
            alert('Please select a pet owner and a pet first');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/.netlify/functions/createPost`, {
                petOwnerId: selectedOwner,
                petId: selectedPet,
                caption,
                captionLong,
                slug,
                fileUrl,  // Add the fileUrl to the post data
                tags: chipData.map(chip => chip.label),
            });

            setSelectedPet('');
            setCaption('');
            setCaptionLong('');
            setSlug('');
            setFileUrl(null);  // Reset the file URL
            setChipData([]);
        } catch (error) {
            console.error('Error creating post', error);
        }
    };

    return (
        <Container maxWidth="xs">

            <Helmet>
                <title>Wagzters - Create Post</title>
                <meta name="description" content="Create a new post in Wagzters - the cloud repository for pet lovers. Share cherished moments of your pet, add cute captions, and tag your furry friends!"/>
                <meta property="og:title" content="Wagzters - Create Post" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://wagzters.com/createpost" />
                <meta property="og:description" content="Create a new post in Wagzters - the cloud repository for pet lovers. Share cherished moments of your pet, add cute captions, and tag your furry friends!" />
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',

            }}>
                <Card sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#f2f2f2',
                    borderRadius: '15px',
                    border: '2px solid #FFFFFF00'
                }}>
                    <CardContent>
                        <h2 style={{
                            color:'#333333'
                        }}>Create Post</h2>
                        <form>
                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <InputLabel id="petOwner-label" sx={{ color: '#9ca3af' }}>Pet Owner</InputLabel>
                                <Select
                                    labelId="petOwner-label"
                                    id="petOwner"
                                    value={selectedOwner}
                                    onChange={handleOwnerChange}
                                    label="Pet Owner"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#1c2734',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#16cc7d',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#15a2fa',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#9ca3af'
                                        }
                                    }}
                                >
                                    <MenuItem value=""><em>Select a pet owner</em></MenuItem>
                                    {owners.map((owner) => (
                                        <MenuItem key={owner.id} value={owner.id}>{owner.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <InputLabel id="pet-label" sx={{ color: '#9ca3af' }}>Pet</InputLabel>
                                <Select
                                    labelId="pet-label"
                                    id="pet"
                                    value={selectedPet}
                                    onChange={(e) => setSelectedPet(e.target.value)}
                                    label="Pet"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#1c2734',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#16cc7d',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#15a2fa',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#9ca3af'
                                        }
                                    }}
                                >
                                    <MenuItem value=""><em>Select a pet</em></MenuItem>
                                    {pets.map((pet) => (
                                        <MenuItem key={pet.id} value={pet.id}>
                                            {pet.name} ({pet.breed}, {pet.age} years old)
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <TextField
                                    id="caption"
                                    label="Caption"
                                    value={caption}
                                    onChange={(e) => setCaption(e.target.value)}
                                    multiline
                                    rows={4}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#1c2734',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#16cc7d',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#15a2fa',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#9ca3af'
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#9ca3af',
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <TextField
                                    id="captionLong"
                                    label="CaptionLong"
                                    value={captionLong}
                                    onChange={(e) => setCaptionLong(e.target.value)}
                                    multiline
                                    rows={4}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#1c2734',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#16cc7d',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#15a2fa',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#9ca3af'
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#9ca3af',
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <TextField
                                    id="slug"
                                    label="slug"
                                    value={slug}
                                    onChange={(e) => setSlug(e.target.value)}
                                    multiline
                                    rows={4}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#1c2734',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#16cc7d',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#15a2fa',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#9ca3af'
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#9ca3af',
                                        }
                                    }}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <Box component="span">
                                    <TextField
                                        id="standard-basic"
                                        label="Add a tag"
                                        onKeyDown={handleAddChip}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#1c2734',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#16cc7d',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#15a2fa',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                color: '#9ca3af'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#9ca3af',
                                            }
                                        }}
                                    />
                                </Box>
                                <Box component="span">
                                    {chipData.map((data, index) => (
                                        <Chip
                                            key={data.key}
                                            label={data.label}
                                            onDelete={handleDelete(data)}
                                            style={{ margin: '5px' }}
                                            color={data.color}
                                        />
                                    ))}
                                </Box>
                            </FormControl>

                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <UploadComponent
                                    setFileUrl={setFileUrl}
                                    setIsUploading={setIsUploading}
                                />
                            </FormControl>

                            <Button
                                // fullWidth
                                width="50%"
                                type="button"
                                variant="contained"
                                onClick={handleCreatePost}
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#15a2fa',
                                    '&:hover': {
                                        backgroundColor: '#FF005C',
                                    },
                                }}
                                disabled={isUploading}
                            >
                                Create Post
                            </Button>
                        </form>

                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default withAuthenticationRequired(CreatePost, {
    onRedirecting: () => <div>Loading...</div>,
});
