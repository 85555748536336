/* eslint-disable no-unused-vars */
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledDialogTitle = styled(DialogTitle)({
    textAlign: 'center',
    '& .MuiTypography-root': {
        fontSize: '1.2rem',
        fontWeight: '600',
    },
});

const StyledButton = styled(Button)({
    display: 'block',
    width: '100%',
    margin: '10px 0',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#333333',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',  // default hover effect for MUI buttons
    }
});

const AboutProduct = ({ open, onClose, product, slug }) => {
    // console.log(product)
    const { user, isAuthenticated } = useAuth0();
    const [petOwnerProfile, setPetOwnerProfile] = useState({
        name: '',
        bio: '',
        fileUrl: '',
    });
    const [productDetails, setProductDetails] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [followStatus, setFollowStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPetOwnerProfile = async () => {
            if (isAuthenticated && user) {
                try {
                    const response = await fetch('/.netlify/functions/getPetOwnerProfile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const petOwnerProfileData = await response.json();

                    setPetOwnerProfile(petOwnerProfileData);
                } catch (error) {
                    console.error('Error fetching pet owner profile:', error);
                }
            }
        };

        fetchPetOwnerProfile();
    }, [user, isAuthenticated]);

    useEffect(() => {
        const fetchProductDetails = async () => {
            const response = await fetch('/.netlify/functions/getProduct', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    slug: product.slug,
                }),
            });
            const data = await response.json();
            setProductDetails(data);
        };

        if (product) {
            fetchProductDetails();
        }

        // Reset state when dialog is closed or product changes
        return () => {
            setShowDetails(false);
            setProductDetails(null);
            setFollowStatus(false);
        };
    }, [product, open]);

    useEffect(() => {
        const fetchFollowStatus = async () => {
            if (productDetails && user) {
                const response = await fetch('/.netlify/functions/getFollowProductStatus', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        productId: productDetails.id,
                        followerEmail: user.email,
                    }),
                });
                const data = await response.json();
                setFollowStatus(data.isFollowing);
            }
        };

        fetchFollowStatus();
    }, [productDetails, user]);

    const followPet = async () => {
        const response = await fetch('/.netlify/functions/followPet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                productId: productDetails.id,
                followerEmail: user.email,
            }),
        });

        if (response.ok) {
            setFollowStatus(true);
        }
    };

    const unfollowPet = async () => {
        const response = await fetch('/.netlify/functions/unfollowPet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                productId: productDetails.id,
                followerEmail: user.email,
            }),
        });

        if (response.ok) {
            setFollowStatus(false);
        }
    };

    if (!productDetails) {
        return null; // or a loading indicator
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth='sm'
            PaperProps={{
                sx: {
                    backgroundColor: '#f2f2f2',
                    // borderColor: '#FFFFFF00',
                    border: '2px solid #FFFFFF00',
                    borderRadius: '20px',
                }
            }}
        >
            {showDetails ? (
                <>
                    <StyledDialogTitle sx={{ color:'#333333' }}>
                        About
                    </StyledDialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1" sx={{ color: '#333333' }}>
                            <strong>Name:</strong> {productDetails.name}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: '#333333' }}>
                            <strong>Price:</strong> {productDetails.age}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: '#333333' }}>
                            <strong>Brand:</strong> {productDetails.breed}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: '#333333' }}>
                            <strong>Details:</strong> {productDetails.bio}
                        </Typography>
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        <StyledButton
                            variant="text"
                            onClick={() => setShowDetails(true)}
                            sx={{ color:'#333333' }}
                        >
                            About
                        </StyledButton>
                        <StyledButton
                            variant="text"
                            onClick={() => navigate(`/productProfile/${product.slug}`)}
                            sx={{ color:'#333333' }}
                        >
                            Product Profile
                        </StyledButton>
                        <StyledButton
                            variant="text"
                            onClick={followStatus ? unfollowPet : followPet}
                            sx={{ color:'#333333' }}
                        >
                            {followStatus ? 'Not interested' : 'Keep an Eye on'}
                        </StyledButton>
                        <StyledButton
                            variant="text"
                            onClick={() => navigate(`/productPostProfile/${slug}`)}
                            sx={{ color:'#333333' }}
                        >
                            Go to Post
                        </StyledButton>
                    </DialogContent>
                </>
            )}
            <DialogActions>
                <StyledButton
                    variant="text"
                    onClick={onClose}
                >
                    Close
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

export default AboutProduct;
