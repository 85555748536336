import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Typography, CircularProgress, Box} from '@mui/material';
import { styled } from '@mui/system';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: '#333333',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const StyledList = styled('ul')({
    listStyleType: 'none',
    paddingLeft: 0,
    marginBottom: '10px',
    position: 'relative',  // set position to relative so we can use pseudo elements

    '&::before': {  // using ::before pseudo-element to create the top border
        content: '""',
        display: 'block',
        width: '90%',  // set width to 80% of the parent
        borderTop: '1px solid #e0e0e0',
        position: 'absolute',
        top: 0,
        left: '0%',  // this will center the border within the parent
    },

    '&::after': {  // using ::after pseudo-element to create the bottom border
        content: '""',
        display: 'block',
        width: '90%',  // set width to 80% of the parent
        borderBottom: '1px solid #e0e0e0',
        position: 'absolute',
        bottom: 0,
        left: '0%',  // this will center the border within the parent
    }
});


const StyledListItem = styled('li')({
    marginBottom: '10px',
    paddingBottom: '10px',
    paddingTop: '10px',
    position: 'relative',  // set position to relative so we can use pseudo elements
    '&::after': {  // using ::after pseudo-element to create the bottom border
        content: '""',
        display: 'block',
        width: '90%',  // set width to 80% of the parent
        borderBottom: '1px solid #e0e0e0',
        position: 'absolute',
        bottom: 0,
        left: '0%',  // this will center the border within the parent
    }
});


const LatestBlogPosts = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch("/.netlify/functions/getBlogPostItemsLatest")
            .then(response => response.json())
            .then(data => {
                setBlogPosts(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching latest blog posts:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="latest-blog-posts"
             style={{ padding: '10px 20px' }}
        >
            <Typography
                variant="h5"
                component="h2"
                align="left"
                sx={{
                    // color:'#333333'
                    color:'#FFFFFF00'
                }}
            >  {/* Added align property here */}
                Latest
            </Typography>
            <StyledList>
                {blogPosts.map(post => (
                    <StyledListItem key={post.blogPost.id}>
                        <StyledLink to={`/blogpost/${post.slugs[0]?.slugName}`}>
                            <Typography
                                variant="body1"
                                align="left"
                                sx={{
                                    padding:'10px 0',
                                    marginRight: '10px',
                                    color:'inherit'

                                }}

                            >  {/* Added align property here */}
                                {post.blogPost.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    fontSize: '0.8rem',
                                    color: 'gray',
                                    padding: '5px 0'
                            }}
                                align="left"
                            >  {/* Added align property here */}
                                Updated on: {post.blogPost.updateDate}
                            </Typography>
                        </StyledLink>
                    </StyledListItem>
                ))}
            </StyledList>
        </div>
    );
};

export default LatestBlogPosts;
