import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledLink = styled('a')({
    textDecoration: 'none',
    color: '#333333',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const StyledList = styled('ul')({
    listStyleType: 'none',
    paddingLeft: 0,
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
});

const StyledListItem = styled('li')({
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
    paddingBottom: '10px',
    paddingTop: '10px',
});

const OriginalPrice = styled(Typography)({
    textDecoration: 'line-through',
    marginRight: '5px',
    color: 'gray',
    display: 'inline', // added this to keep it inline with the discounted price
});

const DiscountedPrice = styled(Typography)({
    color: '#008080', // Teal color
    display: 'inline', // added this to keep it inline with the original price
});

const LatestDeals = () => {
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch("/.netlify/functions/getDealItemsLatest")
            .then(response => response.json())
            .then(data => {
                setDeals(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching latest deals:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="latest-deals"
             style={{
                 padding: '10px 20px'}}
        >
            <Typography
                variant="h5"
                component="h2"
                align="left"
                sx={{
                    // color:'#333333'
                    color:'#FFFFFF00'
                    // marginLeft: '10px',
                }}

            >
                Links
            </Typography>
            <StyledList>
                {deals.map(deal => (
                    <StyledListItem key={deal.deal.id}>
                        <StyledLink href={deal.deal.dealUrl} target="_blank" rel="noopener noreferrer">
                            <img src={deal.deal.fileUrl} alt={deal.deal.title}
                                 style={{
                                     width: '100%',
                                     maxHeight: '200px',
                                     objectFit: 'cover',
                                     // marginLeft:'10px'
                            }} />
                            <Typography
                                variant="body1"
                                align="left"
                                sx={{
                                    color:'inherit',
                                    // marginLeft: '10px',
                                    padding:'5px 0'
                                }}
                            >
                                {deal.deal.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    fontSize: '0.8rem',
                                    color: 'gray',
                                    // marginLeft:'10px',
                            }}
                                align="left"
                            >
                                Start Date: {deal.deal.dealStartDate}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {deal.deal.discountedPrice === "0" ? (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: '#008080',
                                            // marginLeft:'10px'
                                    }}>
                                        Free
                                    </Typography>
                                ) : (
                                    <>
                                        <OriginalPrice variant="body2">${deal.deal.originalPrice}</OriginalPrice>
                                        <DiscountedPrice variant="body2">${deal.deal.discountedPrice}</DiscountedPrice>
                                        {deal.deal.discountPercentage && (
                                            <Typography variant="body2" style={{ marginLeft: '5px', color: '#008080' }}>
                                                ({deal.deal.discountPercentage}% off)
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </div>
                        </StyledLink>
                    </StyledListItem>
                ))}
            </StyledList>
        </div>
    );
};

export default LatestDeals;
