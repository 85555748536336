/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Container,
    CircularProgress,
    Box,
    CardHeader,
    IconButton,
    Grid,
    CardActionArea,
    Link
} from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: 'auto',
});

const TruncatedTypography = styled(Typography)({
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '1em auto', // for centering and spacing
});

const RecommendationCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '1em',
});

function ProductPost() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [productPost, setProductPost] = useState(null);
    const [pet, setPet] = useState(null);
    const [product, setProduct] = useState(null);
    const [petOwner, setPetOwner] = useState(null);
    const [productOwner, setProductOwner] = useState(null);
    const [recommendations, setRecommendations] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.post('/.netlify/functions/getProductPostProfile', { slug })
            .then(response => {
                setProductPost(response.data);
                return axios.post('/.netlify/functions/getProductByProductPostId', { slug });
            })
            .then(response => {
                setProduct(response.data);
                return axios.post('/.netlify/functions/getPetOwnerByProductPostId', { slug });
            })
            .then(response => {
                setProductOwner(response.data);
                return axios.post('/.netlify/functions/getProductRecommendations', { productId: product.id });
            })
            .then(response => {
                setRecommendations(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching post, pet, pet owner profile, and recommendations:', error);
                setIsLoading(false);
            });
    }, [slug]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!productPost) {
        return <p>No product post found for this id.</p>;
    }

    return (
        <Container maxWidth="md">
            <StyledCard>
                <CardHeader
                    action={
                        <IconButton onClick={() => {}}>
                            <MoreVertIcon style={{ color: '#e91e63' }}/>
                        </IconButton>
                    }
                    title={`${product ? product.name : 'Unknown'}`}
                    subheader={`by: ${productOwner ? productOwner.name : 'Unknown'}`}

                />
                {productPost.fileUrl && productPost.fileUrl.endsWith('.mp4') ? (
                    <StyledCardVideo controls>
                        <source src={productPost.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledCardVideo>
                ) : (
                    <StyledCardMedia
                        image={productPost.fileUrl ? productPost.fileUrl : "https://via.placeholder.com/640x360"}
                        title="Product Post image"
                    />
                )}
                <CardContent>
                    <TruncatedTypography variant="body2" color="#9ca3af">
                        {productPost.caption}
                    </TruncatedTypography>
                </CardContent>
            </StyledCard>
            <Typography variant="h5" component="div" style={{marginTop: '1em'}}>
                Recommended Products
            </Typography>
            <Grid container spacing={2}>
                {recommendations && recommendations.map((rec, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Link to={`/productProfile/${rec.id}`} style={{ textDecoration: 'none' }}>
                            <RecommendationCard>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={rec.fileUrl ? rec.fileUrl : "https://via.placeholder.com/640x360"}
                                        alt={rec.name}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            {rec.name}
                                        </Typography>
                                        <Typography variant="body2" color="#9ca3af">
                                            Price: {rec.age}
                                        </Typography>
                                        <Typography variant="body2" color="#9ca3af">
                                            Brand: {rec.breed}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </RecommendationCard>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default ProductPost;
