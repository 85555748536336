/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Box, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { Container } from '@mui/material';
import ItemList from './BlogPostList';
import StoreIcon from '@mui/icons-material/Store';
import { Helmet } from "react-helmet";
import ReviewsIcon from '@mui/icons-material/Reviews';
import BlogPostList from "./BlogPostList";

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledTypographyBio = styled(Typography)({
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    marginBottom: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    padding: '2rem',
    marginTop: '0rem',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem'
});

const BlogPosts = () => {
    const { user, isAuthenticated } = useAuth0();
    const [petOwnerProfile, setPetOwnerProfile] = useState({
        name: '',
        bio: '',
        fileUrl: '',
    });

    useEffect(() => {
        const fetchPetOwnerProfile = async () => {
            if (isAuthenticated && user) {
                try {
                    const response = await fetch('/.netlify/functions/getPetOwnerProfile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const petOwnerProfileData = await response.json();

                    setPetOwnerProfile(petOwnerProfileData);
                } catch (error) {
                    console.error('Error fetching pet owner profile:', error);
                }
            }
        };

        fetchPetOwnerProfile();
    }, [user, isAuthenticated]);

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0
                }}>
                <BlogPostList />
            </Box>
            <Helmet>
                <title>Wagzters - Blog Posts</title>
                <meta name="description" content="Explore the latest blog posts on Wagzters, covering a wide range of topics related to pets, reviews, and more." />
                <link rel="canonical" href="https://wagzters.com/blogposts" />
                <link rel="alternate" hrefLang="en" href="https://wagzters.com/blogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://wagzters.com/blogposts" />
                <meta property="og:title" content="Wagzters - Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://wagzters.com/blogposts" />
                <meta property="og:description" content="Explore the latest blog posts on Wagzters, covering a wide range of topics related to pets, reviews, and more." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Wagzters - Blog Posts" />
                <meta name="twitter:description" content="Explore the latest blog posts on Wagzters, covering a wide range of topics related to pets, reviews, and more." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="robots" content="index, follow" />
            </Helmet>
        </Container>
    );
};

export default BlogPosts;
