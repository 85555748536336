import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


const StyledCardMedia = styled(CardMedia)({
    paddingTop: '100%',
    height: '100%',
    objectFit: 'cover',
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const TruncatedTypography = styled(Typography)({
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
});

const Product = ({ productProp }) => {
    if (!productProp) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </div>;
    }


    return (
        <StyledLink to={`/productProfile/${productProp.slug}`}>
            <Card
                sx={{
                    maxWidth: '100%',
                    height: '360px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#f2f2f2',
                    borderRadius: '15px',
                    border: '2px solid transparent'
                }}>
                {productProp.fileUrl && productProp.fileUrl.endsWith('.mp4') ? (
                    <StyledCardVideo controls>
                        <source src={productProp.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledCardVideo>
                ) : (
                    <StyledCardMedia
                        image={productProp.fileUrl || "https://via.placeholder.com/640x360"}
                        title="Product image"
                    />
                )}
                <CardContent>
                    <TruncatedTypography
                        variant="body2"
                        color="#16cc7d"
                    >
                        {productProp.name}
                    </TruncatedTypography>
                </CardContent>
            </Card>
        </StyledLink>
    );
};

export default Product;
