/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {Card, CardMedia, CardContent, Typography, Container, Grid, Link, CardActionArea, CircularProgress} from '@mui/material';
import { styled } from '@mui/system';
import Product from '../Products/Product';
import ProductPost from '../ProductPosts/ProductPost';
import axios from 'axios';
import ReactMarkdown from "react-markdown";


const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: 'auto',
});

const TruncatedTypography = styled(Typography)({
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '1em auto', // for centering and spacing
});

const RecommendationCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '1em',
    border: '2px solid #FFFFFF00'
});


const ProductProfile = () => {
    const [productDetails, setProductDetails] = useState(null);
    const [productPosts, setProductPosts] = useState([]);
    const { slug } = useParams(); // Extract the petId from the route params
    const [recommendations, setRecommendations] = useState(null);
    const [productRecommendations, setProductRecommendations] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productDetailsPromise = fetch('/.netlify/functions/getProduct', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ slug }),
                }).then(res => res.json());

                const productPostsPromise = fetch('/.netlify/functions/getProductPostsByProduct', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ slug }),
                }).then(res => res.json());

                const recommendationsPromise = axios.post('/.netlify/functions/getProductRecommendations', { slug }).then(res => res.data);

                // Wait for all promises to resolve
                const [details, posts, recs] = await Promise.all([productDetailsPromise, productPostsPromise, recommendationsPromise]);
                // console.log(details)
                setProductDetails(details);
                setProductPosts(posts);
                setProductRecommendations(recs);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [slug]);


    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (!productDetails) {
        return <div>Product not found.</div>;
    }

    return (
        <Container maxWidth="md">
            <StyledCard
                sx={{
                    border: '2px solid #FFFFFF00'
                }}
            >
                {productDetails.fileUrl && productDetails.fileUrl.endsWith('.mp4') ? (
                    <StyledCardVideo controls>
                        <source src={productDetails.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledCardVideo>
                ) : (
                    <StyledCardMedia
                        image={productDetails.fileUrl ? productDetails.fileUrl : "https://via.placeholder.com/640x360"}
                        title="Product image"
                    />
                )}
                <CardContent>
                    <Typography
                        variant="h4"
                        sx={{
                            color:'#16cc7d'
                        }}
                    >{productDetails.name}</Typography>
                    <TruncatedTypography
                        variant="body2"
                        color="#9ca3af"
                    >
                        <strong>Price: </strong> {productDetails.age}
                    </TruncatedTypography>
                    <TruncatedTypography
                        variant="body2"
                        color="#9ca3af"
                    >
                        <strong>Brand: </strong> {productDetails.breed}
                    </TruncatedTypography>
                    {/*<TruncatedTypography*/}
                    {/*    variant="body2"*/}
                    {/*    color="#9ca3af"*/}
                    {/*>*/}
                    {/*    <strong>Details: </strong> {productDetails.bio}*/}
                    {/*</TruncatedTypography>*/}
                    {/* Add more pet attributes if you have them */}
                </CardContent>
            </StyledCard>
            {/*<div className="markdown-container" style={{*/}
            {/*    backgroundColor:'#f2f2f2',*/}
            {/*    padding:'15px',*/}
            {/*    borderRadius:'15px',*/}
            {/*    marginBottom:'15px',*/}
            {/*    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',*/}
            {/*    textAlign:'left',*/}
            {/*    color:'#4b5360'*/}
            {/*}}>*/}
            {/*    <CardContent>*/}
            {/*        <Typography*/}
            {/*            variant="h5"*/}
            {/*            sx={{*/}
            {/*                color:'#333333'*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {productDetails.bio}*/}
            {/*        </Typography>*/}
            {/*    </CardContent>*/}
            {/*</div>*/}
            <div className="markdown-container" style={{
                backgroundColor:'#f2f2f2',
                padding:'15px',
                borderRadius:'15px',
                marginBottom:'15px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                textAlign:'left',
                color:'#4b5360'
            }}>

                <ReactMarkdown>
                    {productDetails.bio}
                </ReactMarkdown>
            </div>

            <Typography
                variant="h6"
                component="div"
                style={{
                    marginTop: '1em',
                    color:'#333333'
                }}>
                Posts related to {productDetails.name}
            </Typography>

            <Grid container spacing={2}>
                {productPosts.map(post => (
                    <Grid item xs={12} sm={6} md={4} key={post.id}
                          style={{
                              minHeight: '500px'
                    }}
                    >
                        {/*<StyledCard>*/}
                            <ProductPost post={post} />
                        {/*</StyledCard>*/}
                    </Grid>
                ))}
            </Grid>

            <Typography
                variant="h6"
                component="div" style={{
                marginTop: '1em',
                color:'#333333'
            }}>
                Suggested Products
            </Typography>

            <Grid container spacing={2}>
                {productRecommendations && productRecommendations.map((rec, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Link to={`/productProfile/${rec.id}`} style={{ textDecoration: 'none' }}>
                            <RecommendationCard>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={rec.fileUrl ? rec.fileUrl : "https://via.placeholder.com/640x360"}
                                        alt={rec.name}
                                    />
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color:'#16cc7d'
                                            }}
                                        >
                                            {rec.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="#9ca3af"
                                        >
                                            Price: {rec.age}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="#9ca3af"
                                        >
                                            Brand: {rec.breed}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </RecommendationCard>
                        </Link>
                    </Grid>
                ))}
            </Grid>

        </Container>
    );
};

export default ProductProfile;
