import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, FormControl } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const UploadComponent = ({ setFileUrl, setIsUploading }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('No file chosen');

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileName(e.target.files[0]?.name || 'No file chosen');
    };

    const handleUpload = async () => {
        setIsUploading(true);

        if (!selectedFile) {
            alert('Please select a file first');
            setIsUploading(false);
            return;
        }

        try {
            // Step 1: Request a signed URL
            const response = await axios.post(`${API_BASE_URL}/.netlify/functions/generateSignedUrl`, {
                fileName: selectedFile.name,
                contentType: selectedFile.type
            });

            const signedUrl = response.data.signedUrl;

            // Step 2: Upload the file to S3
            await axios.put(signedUrl, selectedFile, {
                headers: {
                    'Content-Type': selectedFile.type,
                }
            });

            // Step 3: Save the file URL to be used when creating the post
            const fileUrl = signedUrl.split('?')[0];
            setFileUrl(fileUrl);

        } catch (error) {
            console.error('Error uploading file', error);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div>
            <FormControl
                fullWidth
                margin="normal"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#1c2734',
                        },
                        '&:hover fieldset': {
                            borderColor: '#16cc7d',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#15a2fa',
                        }
                    },
                    '& .MuiInputBase-input': {
                        color: '#9ca3af'
                    }
                }}
            >
                <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Choose file"
                    value={fileName}
                    InputProps={{
                        readOnly: true,
                    }}
                    onClick={() => document.getElementById('fileInput').click()}
                    sx={{
                        '& .MuiInputBase-input': {
                            color: '#9ca3af', // Input text color
                        },
                        '& .MuiInputLabel-root': {
                            color: '#9ca3af', // Label color
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#1c2734', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: '#16cc7d', // Hover outline color
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#15a2fa', // Focused outline color
                            }
                        }
                    }}
                />
                <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />

            </FormControl>




            <Button
                // fullWidth
                width="50%"
                variant="contained"
                onClick={handleUpload}
                sx={{
                    mt: 2,
                    backgroundColor: '#15a2fa',
                    '&:hover': {
                        backgroundColor: '#FF005C',
                    },
                }}
            >
                Upload
            </Button>
        </div>
    );
};

export default UploadComponent;
