import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, CardMedia, CardContent, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { Helmet } from "react-helmet";

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: 'auto',
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '1em auto', // for centering and spacing
    border: '2px solid #FFFFFF00'
});

function BlogPost() {
    const { slugName } = useParams();
    const [item, setItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.post('/.netlify/functions/getBlogPostItem', { slugName })
            .then(response => {
                setItem(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching item:', error);
                setIsLoading(false);
            });
    }, [slugName]);

    const createMarkup = htmlContent => {
        return {__html: htmlContent};
    };

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </div>;
    }

    if (!item) {
        return <p>No item found for this id.</p>;
    }

    return (
        <Container maxWidth="md">

            <StyledCard>
                {item.fileUrl && item.fileUrl.endsWith('.mp4') ? (
                    <StyledCardVideo controls>
                        <source src={item.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledCardVideo>
                ) : (
                    <StyledCardMedia
                        image={item.fileUrl ? item.fileUrl : "https://via.placeholder.com/640x360"}
                        title="Item image"
                    />
                )}
                <CardContent>
                    <Typography
                        variant="h5"
                        sx={{
                            color:'#333333'
                        }}
                    >
                        {item.title}
                    </Typography>
                </CardContent>
            </StyledCard>
            <div
                className="markdown-container"
                style={{
                    backgroundColor: '#f2f2f2',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '15px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'left',
                    color: '#4b5360',
                }}
                dangerouslySetInnerHTML={createMarkup(item.detailedReview)}
            />
            <Helmet>
                <title>{item.title ? `${item.title} - Wagzters` : 'Blog Post - Wagzters'}</title>
                <link rel="canonical" href={`https://wagzters.com/blogpost/${slugName}`} />
                <meta
                    name="description"
                    content={item.detailedReview ? `${item.detailedReview.substring(0, 150)}...` : "Explore Blog Posts on Wagzters."}
                />
                <link rel="alternate" hrefLang="en" href={`https://wagzters.com/blogpost/${slugName}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://wagzters.com/blogpost/${slugName}`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={item.title ? `${item.title} - Wagzters` : 'Blog Post - Wagzters'} />
                <meta property="og:description" content={item.detailedReview ? `${item.detailedReview.substring(0, 150)}...` : "Explore Blog Posts on Wagzters."} />
                <meta property="og:url" content={`https://wagzters.com/blogpost/${slugName}`} />
                <meta property="og:image" content={item.fileUrl || "https://via.placeholder.com/640x360"} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={item.title ? `${item.title} - Wagzters` : 'Blog Post - Wagzters'} />
                <meta name="twitter:description" content={item.detailedReview ? `${item.detailedReview.substring(0, 150)}...` : "Explore Blog Posts on Wagzters."} />
                <meta name="twitter:image" content={item.fileUrl || "https://via.placeholder.com/640x360"} />
                <meta name="author" content="Wagzters" />
                <meta name="robots" content="index, follow" />
            </Helmet>
        </Container>
    );
}

export default BlogPost;
