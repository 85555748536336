import React from 'react';

const imgWrapperStyle = {
    width: '208px',
    height: '167px',
    border: 'none',
    overflow: 'hidden',
    display: 'block',
    margin: 'auto'
};

const Games = () => {

    return (
        <div className="games-widget" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <div style={imgWrapperStyle}>
                <a href="https://catdoku.io" title="Play Catdoku" target="_blank" rel="noopener noreferrer">
                    <img src="https://i.imgur.com/qCrcLWd.png" alt="Play Catdoku" style={{ width: '100%', height: '100%' }} />
                </a>
            </div>
            <iframe
                src="https://itch.io/embed/2514669?bg_color=e6e6e6&amp;fg_color=eeeeee&amp;link_color=fa5c5c&amp;border_color=e6e6e6&amp"
                width="208"
                height="167"
                frameBorder="0"
                title="Dogoku Game Widget"
                allowFullScreen>
                <a href="https://wagzters.itch.io/dogoku">Play Dogoku on wagzters.itch.io</a>
            </iframe>
            <iframe
                src="https://itch.io/embed/2505702?bg_color=e6e6e6&amp;fg_color=eeeeee&amp;link_color=fa5c5c&amp;border_color=e6e6e6&amp"
                width="208"
                height="167"
                frameBorder="0"
                title="Mini Sudoku Game Widget"
                allowFullScreen>
                <a href="https://wagzters.itch.io/mini-sudoku">Play Mini Sudoku on wagzters.itch.io</a>
            </iframe>
        </div>
    );
};

export default Games;
