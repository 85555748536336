/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Home from './Home'; // import the Home component
import { Helmet } from 'react-helmet'; // import Helmet component
import Tooltip from '@mui/material/Tooltip';
import { keyframes } from '@mui/system';
import './sharedButtons.css'

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Favorites = () => {
    const { user, isAuthenticated } = useAuth0();
    const [likedPosts, setLikedPosts] = useState([]);
    const iconButtonSx = {
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(2.0)',
        },
        '& .MuiSvgIcon-root': { // Target the icon inside the IconButton
            transition: 'color 0.3s ease-in-out',
        },
        '&:hover .MuiSvgIcon-root': {
            color: '#68FF00',
        },
    };

    useEffect(() => {
        const fetchLikedPosts = async () => {
            if (isAuthenticated && user) {
                const response = await fetch('/.netlify/functions/getLikedPosts', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userEmail: user.email }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }

                const data = await response.json();
                setLikedPosts(data);
            }
        };

        fetchLikedPosts();
    }, [isAuthenticated, user]);

    return (
        <>
            <Helmet>
                <title>Wagzters - Favorites</title>
                <meta name="description" content="Wagzters - Browse your favorite pet posts, cherishing the delightful moments with the pet stars."/>
                <meta property="og:title" content="Wagzters - Favorites" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://wagzters.com/favorites" />
                <meta property="og:description" content="Wagzters - Explore the posts you've favorited, indulging in the warmth and joy each pet brings." />
            </Helmet>
            <Home filterPosts={likedPosts} /> {/* render the Home component with only the liked posts */}
        </>
    );
};

export default Favorites;
