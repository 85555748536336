/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {React,useEffect,useState,useAuth0, Container,Grid,Card,CardHeader,CardContent,Typography,CircularProgress,Alert,CardMedia,IconButton,CardActions,Box,Modal,MenuItem,MenuList,Button,styled,FavoriteIcon,FavoriteBorderIcon,CommentIcon,ShareIcon,SaveIcon,MoreVertIcon,Avatar,Comments,ShareForm,AboutPet} from './imports';
import axios from 'axios';
import { StyledCard, StyledCardMedia, StyledCardVideo } from './styledComponents';
import likePostHome from './likePostHome';
import savePost from './savePost';
import likeComment from './likeComment';
import handleFollowUser from './handleFollowUser';
import { handleSharePost } from './handleSharePost';
import {PersonAdd} from "@mui/icons-material";
import { fetchSearchResults } from './searchHelperTimeline';
import { fetchPetOwnerProfileHelper } from './fetchPetOwnerProfileHelper';
import { getPetOwnerName } from './getPetOwnerNameHelper';
import fetchLikedPosts from './fetchLikedPosts';
import fetchSavedPosts from './fetchSavedPosts';
import fetchLikedComments from './fetchLikedComments';
import fetchFollowedUsers from './fetchFollowedUsers';
import { Helmet } from 'react-helmet';
import LatestBlogPosts from '../components/Latest/LatestBlogPosts';
import LatestDeals from '../components/Deals/LatestDeals';
import Games from '../components/Games/Games';
import { FacebookShareButton, TwitterShareButton, XIcon, FacebookIcon, TwitterIcon, PinterestShareButton, PinterestIcon, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon, RedditShareButton, RedditIcon, TelegramShareButton, TelegramIcon, EmailShareButton, EmailIcon } from 'react-share';
import Tooltip from '@mui/material/Tooltip';
import { keyframes } from '@mui/system';
import './sharedButtons.css'

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

function Timeline({ filterPosts, searchString }) {
    const { user, isAuthenticated } = useAuth0();
    const [petOwnerName, setPetOwnerName] = useState('');
    const [petOwnerProfile, setPetOwnerProfile] = useState({
        name: '',
        bio: '',
        fileUrl: '',
    });
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);
    const [likedPosts, setLikedPosts] = useState({});
    const [savedPosts, setSavedPosts] = useState({});
    const [commentsOpen, setCommentsOpen] = useState(false);
    const [currentPostId, setCurrentPostId] = useState(null);
    const [currentSlug, setCurrentSlug] = useState(null);
    const [likedComments, setLikedComments] = useState({});
    const [followedUsers, setFollowedUsers] = useState({});
    const [shareFormOpen, setShareFormOpen] = useState(false);
    const [currentPostToShare, setCurrentPostToShare] = useState(null);
    const [aboutPetOpen, setAboutPetOpen] = useState(false);
    const [currentPet, setCurrentPet] = useState(null);
    const [likeCounts, setLikeCounts] = useState({});
    const [hasFetched, setHasFetched] = useState(false);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [postsSearch, setPostsSearch] = useState([]);

    const iconButtonSx = {
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(2.0)',
        },
        '& .MuiSvgIcon-root': { // Target the icon inside the IconButton
            transition: 'color 0.3s ease-in-out',
        },
        '&:hover .MuiSvgIcon-root': {
            color: '#68FF00',
        },
    };

    const StyledCardVideo = styled('video')({
        width: '100%',  // This makes sure the width is consistent
        height: '450px',  // Fixed height
        objectFit: 'cover',  // Ensures the video covers the area without distortion
        overflow: 'hidden',  // Hides any part of the video that might overflow
    });


    const handleVideoPlay = (video) => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    video.play();
                } else {
                    video.pause();
                }
            });
        }, {
            threshold: 0.5  // Adjust as needed, 0.5 means 50% of the video should be visible
        });

        observer.observe(video);

        // Clean up the observer when the video is unmounted
        return () => observer.disconnect();
    };

    const handleVideoPause = (video) => {
        // handle any additional logic when video is paused
    };

    const openComments = (postId) => {
        setCurrentPostId(postId);
        setCommentsOpen(true);
    };
    const closeComments = () => {
        setCurrentPostId(null);
        setCommentsOpen(false);
    };

    const handlePostSharing = (shareToEmail) => {
        handleSharePost(shareToEmail, user, currentPostToShare);
    };

    const handleAboutPet = (pet, id, slug) => {
        setCurrentPet(pet);
        setCurrentPostId(id);
        setCurrentSlug(slug)
        setAboutPetOpen(true);
    };

    useEffect(() => {
        const fetchPetOwnerProfile = async () => {
            if (isAuthenticated && user) {
                const petOwnerProfileData = await fetchPetOwnerProfileHelper(user.email);
                if (petOwnerProfileData) {
                    setPetOwnerProfile(petOwnerProfileData);
                }
            }
        };

        fetchPetOwnerProfile();
    }, [user, isAuthenticated]);

    useEffect(() => {
        if (searchString.trim() !== '') {
            fetchSearchResults(searchString, setPostsSearch);
        }
    }, [searchString]);

    const fetchPosts = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`/api/listPostsScroll?page=${page}`);
            setPosts(prevPosts => [...prevPosts, ...response.data]);
            setPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error(`Failed to fetch posts: ${error}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    // When the user scrolls down 80% of the view height, load more posts.
    useEffect(() => {
        const handleScroll = () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight * 0.8) {
                fetchPosts();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchPosts]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {

                // fetch the liked posts only if the user is authenticated
                if (isAuthenticated && user) {

                    try {
                        const petOwnerNameData = await getPetOwnerName(user.email);
                        setPetOwnerName(petOwnerNameData);
                    } catch (error) {
                        console.error(error);
                    }

                    fetchLikedPosts(user.email, setLikedPosts);

                    fetchSavedPosts(user.email, setSavedPosts);

                    fetchLikedComments(user.email, setLikedComments);

                    fetchFollowedUsers(user.email, setFollowedUsers);

                }
            } catch (error) {
                console.error('Error fetching posts:', error);
                setError(error.message);
                setLoading(false);
                setHasFetched(true);
            }
        };

        fetchUserData();
    }, [isAuthenticated, user]);


    return (
        <Container maxWidth="xl">

            <Helmet>
                <title>Wagzters - Timeline</title>
                <meta property="og:title" content="Wagzters - Timeline - the App for Digital Pet Profiles" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://wagzters.com/timeline" />
                <meta property="og:description" content="Wagzters - Timeline - the App for Digital Pet Profiles - share, cherish, and explore memories of your furry friends, making them the stars of the show." />
                <link rel="alternate" hrefLang="en" href={`https://wagzters.com/timeline`} />
                <link rel="alternate" hrefLang="x-default" href={`https://wagzters.com/timeline`} />
            </Helmet>

            <Grid container spacing={3}>

                {/* Left column for LatestBlogPosts */}
                <Grid item xs={12} md={2.5}>
                    {/*<LatestBlogPosts />*/}
                    <Games/>
                </Grid>
                {/*<Grid item xs={12} md={2.5}>*/}
                {/*    <LatestBlogPosts />*/}
                {/*</Grid>*/}

                {/* Center/Main content */}
                <Grid item xs={12} md={7}>

                    <Typography
                        variant="h6"
                        sx={{
                            marginBottom: 3,
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            textTransform: 'uppercase',
                            background: 'linear-gradient(to right, #f06, #48f, #16cc7d, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                        }}
                    >
                        Wagzters | The App for Digital Pet Profiles
                    </Typography>


                    {isAuthenticated && user && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2, mb: 4 }}>
                            <Avatar
                                alt={petOwnerProfile.name || ''}
                                src={petOwnerProfile.fileUrl || user.picture}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    marginRight: '1rem',
                                    color:'#15a2fa'
                                }}
                            />
                            <Typography variant="h6" sx={{ color:'#16cc7d' }}>
                                {petOwnerProfile.name}
                            </Typography>
                        </Box>
                    )}

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {`Error fetching data: ${error}`}
                        </Alert>
                    )}

                    <Grid container spacing={2} justifyContent="center">
                        {(searchString.trim() !== '' ? postsSearch : posts).map((post, index) => (
                            <Grid item xs={12} key={index}>

                                <StyledCard
                                    sx={{
                                        border: '2px solid #FFFFFF00'
                                    }}
                                >
                                    <CardHeader
                                        action={
                                            <IconButton onClick={() => handleAboutPet(post.pet, post.id, post.slug)}>
                                                <MoreVertIcon style={{ color: '#e91e63' }}/>
                                            </IconButton>
                                        }
                                        sx={{
                                            color:'#16cc7d'
                                        }}
                                        title={`${post.pet ? post.pet.name : 'Unknown'}`}
                                    />
                                    {post.fileUrl && post.fileUrl.endsWith('.mp4') ? (
                                        <StyledCardVideo
                                            autoPlay
                                            muted
                                            loop
                                            ref={video => video && handleVideoPlay(video)}
                                        >
                                            <source src={post.fileUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </StyledCardVideo>
                                    ) : (
                                        <StyledCardMedia
                                            image={post.fileUrl ? post.fileUrl : "https://via.placeholder.com/640x360"}
                                            title="Post image"
                                        />
                                    )}
                                    <CardContent>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color:'#333333'
                                            }}
                                        >
                                            {post.caption}
                                        </Typography>
                                        <Typography variant="subheader" sx={{ color:'grey' }}>
                                            {`by: ${post.owner ? post.owner.name : 'Unknown'}`}
                                        </Typography>
                                    </CardContent>

                                    <CardActions
                                        disableSpacing
                                        sx={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}
                                    >
                                        {/* Add to favorites button */}
                                        <Tooltip title="Add to favorites">
                                            <IconButton
                                                aria-label="add to favorites"
                                                onClick={() => likePostHome(user, post.id, likedPosts[post.id], setLikedPosts, setLikeCounts)}
                                                sx={iconButtonSx}
                                            >
                                                {likedPosts[post.id] ? <FavoriteIcon sx={{ color: likedPosts[post.id] ? '#e91e63' : '#607d8b' }}/> : <FavoriteBorderIcon sx={{ color: '#607d8b' }}/>}
                                            </IconButton>
                                        </Tooltip>
                                        {/* Comment button */}
                                        <Tooltip title="Comment">
                                            <IconButton
                                                aria-label="comment"
                                                onClick={() => openComments(post.id)}
                                                sx={iconButtonSx}
                                            >
                                                <CommentIcon sx={{ color: '#607d8b' }}/>
                                            </IconButton>
                                        </Tooltip>
                                        {/* Share button */}
                                        <Tooltip title="Share">
                                            <IconButton
                                                aria-label="share"
                                                onClick={() => {setCurrentPostToShare(post.id); setShareFormOpen(true);}}
                                                sx={iconButtonSx}
                                            >
                                                <ShareIcon sx={{ color: '#607d8b' }}/>
                                            </IconButton>
                                        </Tooltip>
                                        {/* Save button */}
                                        <Tooltip title="Save">
                                            <IconButton
                                                aria-label="save"
                                                onClick={() => savePost(user, post.id, savedPosts[post.id], setSavedPosts)}
                                                sx={iconButtonSx}
                                            >
                                                {savedPosts[post.id] ? <SaveIcon sx={{ color: '#15a2fa' }}/> : <SaveIcon sx={{ color: '#607d8b' }}/>}
                                            </IconButton>
                                        </Tooltip>
                                        {/* Follow button */}
                                        <Tooltip title="Follow">
                                            <IconButton
                                                aria-label="follow"
                                                onClick={() => handleFollowUser(post.owner.hashEmail, user, followedUsers, setFollowedUsers)}
                                                sx={{
                                                    ...iconButtonSx,
                                                    marginRight: '8px', // Add marginRight specifically for this button
                                                }}
                                            >
                                                {followedUsers[post.owner.hashEmail] ? <PersonAdd sx={{ color: '#15a2fa' }}/> : <PersonAdd sx={{ color: '#607d8b' }}/>}
                                            </IconButton>
                                        </Tooltip>

                                        {/* Pinterest Share Button */}
                                        <PinterestShareButton
                                            url={`https://wagzters.com/postprofile/${post.slug}`}
                                            media={post.fileUrl ? post.fileUrl : "https://via.placeholder.com/640x360"}
                                            description={"Check out this post on Wagzters!"}
                                            className="shareButton" // Apply the class here
                                            style={{ marginRight: '16px' }}
                                        >
                                            <PinterestIcon size={32} round />
                                        </PinterestShareButton>

                                        {/* Twitter Share Button */}
                                        <TwitterShareButton
                                            url={`https://wagzters.com/postprofile/${post.slug}`}
                                            title={"Check out this post on Wagzters!"}
                                            hashtags={["Wagzters", "Pets"]}
                                            className="shareButton" // Apply the class here
                                            style={{ marginRight: '16px' }}
                                        >
                                            <XIcon size={32} round />
                                        </TwitterShareButton>

                                        {/* Facebook Share Button */}
                                        <FacebookShareButton
                                            url={`https://wagzters.com/postprofile/${post.slug}`}
                                            quote={"Check out this post on Wagzters!"}
                                            hashtag={"#Wagzters"}
                                            className="shareButton" // Apply the class here
                                            style={{ marginRight: '16px' }}
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>

                                        {/*<RedditShareButton*/}
                                        {/*    url={`https://wagzters.com/postprofile/${post.id}`}*/}
                                        {/*    title={"Check out this post on Wagzters!"}*/}
                                        {/*    style={{ marginRight: '16px' }}*/}
                                        {/*>*/}
                                        {/*    <RedditIcon size={32} round />*/}
                                        {/*</RedditShareButton>*/}

                                        {/*<LinkedinShareButton*/}
                                        {/*    url={`https://wagzters.com/postprofile/${post.id}`}*/}
                                        {/*    title={"Check out this post on Wagzters!"}*/}
                                        {/*    summary={post.caption}*/}
                                        {/*    source={"Wagzters"}  // Name of the website*/}
                                        {/*    style={{ marginRight: '16px' }}*/}
                                        {/*>*/}
                                        {/*    <LinkedinIcon size={32} round />*/}
                                        {/*</LinkedinShareButton>*/}

                                        {/*<WhatsappShareButton*/}
                                        {/*    url={`https://wagzters.com/postprofile/${post.id}`}*/}
                                        {/*    title={"Check out this post on Wagzters!"}*/}
                                        {/*    separator={" - "}*/}
                                        {/*    style={{ marginRight: '16px' }}*/}
                                        {/*>*/}
                                        {/*    <WhatsappIcon size={32} round />*/}
                                        {/*</WhatsappShareButton>*/}

                                        {/*<TelegramShareButton*/}
                                        {/*    url={`https://wagzters.com/postprofile/${post.id}`}*/}
                                        {/*    title={"Check out this post on Wagzters!"}*/}
                                        {/*    style={{ marginRight: '16px' }}*/}
                                        {/*>*/}
                                        {/*    <TelegramIcon size={32} round />*/}
                                        {/*</TelegramShareButton>*/}

                                        {/*<EmailShareButton*/}
                                        {/*    url={`https://wagzters.com/postprofile/${post.id}`}*/}
                                        {/*    subject={"Check out this post on Wagzters!"}*/}
                                        {/*    body={"Look at this amazing post: "}*/}
                                        {/*    separator={" - "}*/}
                                        {/*    style={{ marginRight: '16px' }}*/}
                                        {/*>*/}
                                        {/*    <EmailIcon size={32} round />*/}
                                        {/*</EmailShareButton>*/}

                                    </CardActions>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>

                    {loading &&
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Grid>
                    }

                    {commentsOpen && user && (
                        <Comments
                            postId={currentPostId}
                            userEmail={user.email}
                            userPicture={user.picture}
                            closeComments={closeComments}
                            likedComments={likedComments}
                            commentsOpen={commentsOpen}
                            handleClose={closeComments}
                        />
                    )}

                    {user && (
                        <ShareForm
                            open={shareFormOpen}
                            onClose={() => setShareFormOpen(false)}
                            onShare={handlePostSharing}
                        />
                    )}

                    {aboutPetOpen && currentPet && (
                        <AboutPet
                            open={aboutPetOpen}
                            onClose={() => setAboutPetOpen(false)}
                            pet={currentPet}
                            post={currentPostId}
                            slug={currentSlug}
                        />
                    )}
                </Grid>

                {/* Right column LatestDeals*/}
                <Grid item xs={12} md={2.5}>
                    {/* Your advertising content or components would go here */}
                    {/*<LatestDeals/>*/}
                    <LatestBlogPosts />
                </Grid>
                {/*<Grid item xs={12} md={2.5}>*/}
                {/*    /!* Your advertising content or components would go here *!/*/}
                {/*    <LatestDeals/>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );


}

export default Timeline;
