/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { motion } from 'framer-motion';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    TextField, // import TextField
    InputAdornment,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth0 } from '@auth0/auth0-react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share';
import FeedbackIcon from '@mui/icons-material/Feedback';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EventIcon from '@mui/icons-material/Event';
import PortraitIcon from '@mui/icons-material/Portrait';
import ForumIcon from '@mui/icons-material/Forum';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentIcon from '@mui/icons-material/Payment';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import ExploreIcon from '@mui/icons-material/Explore';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import FeedIcon from '@mui/icons-material/Feed';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faXTwitter, faPinterest, faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { keyframes } from '@mui/system';
import './iconButtons.css'
import TimelineIcon from '@mui/icons-material/Timeline';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const drawerWidth = 255;

const drawerVariant = {
    hidden: { x: '-100%' },
    visible: { x: '0%', transition: { duration: 0.5 } },
    exit: { x: '-100%', transition: { ease: 'easeInOut' } },
};

const NavBar = ({setSearchString}) => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [localSearchString, setLocalSearchString] = useState('');

    useEffect(() => {
        setSearchString(localSearchString);
    }, [localSearchString]);

    const handleSearchChange = (event) => {
        setLocalSearchString(event.target.value);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawerItems = [
        {
            text: 'Home',
            icon: <HomeIcon style={{
                color: '#15a2fa'
            }}/>,
            route: '/',
        },
        {
            text: 'Timeline',
            icon: <TimelineIcon style={{
                color: '#15a2fa'
            }}/>,
            route: '/timeline',
        },
        {
            text: 'Blog',
            icon: <RssFeedIcon style={{
                color: '#0ab0f8'
            }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
            route: '/blogposts',
        },
        {
            text: 'Deals',
            icon: <LocalOfferIcon style={{
                color: '#05b7f6'
            }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
            route: '/deals',
        },
        // {
        //     text: 'Latest',
        //     icon: <RssFeedIcon style={{
        //         color: '#2a007a'
        //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
        //     route: '/latestblogposts',
        // },
        ...(isAuthenticated
            ? [
                {
                    text: 'My Profile',
                    icon: <AccountCircleIcon style={{
                        color: '#00BFF5'
                    }}/>, // import AccountCircleIcon from '@mui/icons-material/AccountCircle'
                    route: '/profile',
                },
                {
                    text: 'My Pets',
                    icon: <FormatListNumberedRtlIcon style={{
                        color: '#00EFEF'
                    }}/>,
                    route: '/mypets',
                },
                {
                    text: 'My Products',
                    icon: <FormatListNumberedRtlIcon style={{
                        color: '#00EFEF'
                    }}/>,
                    route: '/myproducts',
                },
                {
                    text: 'Create Owner',
                    icon: <PersonIcon style={{
                        color: '#13F4EF'
                    }}/>,
                    route: '/createPetOwner',
                },
                {
                    text: 'Create Pet',
                    icon: <PetsIcon style={{
                        color: '#39F9D8'
                    }}/>,
                    route: '/createPet',
                },
                {
                    text: 'Pet Post',
                    icon: <PostAddIcon style={{
                        color: '#5FFEC0'
                    }}/>,
                    route: '/post',
                },
                {
                    text: 'Create Product',
                    icon: <AddIcon style={{
                        color: '#39F9D8'
                    }}/>,
                    route: '/createProduct',
                },
                {
                    text: 'Product Post',
                    icon: <PostAddIcon style={{
                        color: '#5FFEC0'
                    }}/>,
                    route: '/createProductPost',
                },
                {
                    text: 'Favorites',
                    icon: <FavoriteIcon style={{
                        color: '#85FEA9'
                    }}/>, // import FavoriteIcon from '@mui/icons-material/Favorite';
                    route: '/favorites',
                },
                {
                    text: 'Saved',
                    icon: <BookmarkIcon style={{
                        color: '#68FF00'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/saved',
                },
                {
                    text: 'Following',
                    icon: <PeopleIcon style={{
                        color: '#8CFF33'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/following',
                },
                {
                    text: 'Shared With Me',
                    icon: <ShareIcon style={{
                        color: '#B1FF66'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/sharedwithme',
                },
                {
                    text: 'Community',
                    icon: <ForumIcon style={{
                        color: '#D6FF99'
                    }}/>,
                    route: '/createCommunity',
                },
                {
                    text: 'Community ProductPost',
                    icon: <AddBoxIcon style={{
                        color: '#D6BF00'
                    }}/>,
                    route: '/communityPost',
                },
                {
                    text: 'Communities',
                    icon: <GroupsIcon style={{
                        color: '#FECB20'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/communities',
                },
                {
                    text: 'Shopping',
                    icon: <StorefrontIcon style={{
                        color: '#FE9740'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/shop',
                },
                {
                    text: 'Events',
                    icon: <EventIcon style={{
                        color: '#FFBF00'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/events',
                },
                {
                    text: 'People',
                    icon: <PortraitIcon style={{
                        color: '#FF9973'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/publicfigures',
                },
                {
                    text: 'Payments',
                    icon: <PaymentIcon style={{
                        color: '#FF7387'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/payments',
                },
                {
                    text: 'Polls',
                    icon: <HowToVoteIcon style={{
                        color: '#FF4D9A'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/polls',
                },

                {
                    text: 'Feedback',
                    icon: <FeedbackIcon style={{
                        color: '#FF005C'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/feedback',
                },
            ]
            : []),
    ];

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#a3e4fa',
                    padding: '3px',
                    borderBottom: '2px solid #FFFFFF00',
                    height: '68px',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="gray"
                            aria-label="menu"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon style={{
                                // color: '#333333'
                                color: '#16cc7d'
                            }} />
                        </IconButton>
                        <Typography
                            variant="h6"
                            sx={{
                                // marginBottom: 3,
                                marginRight: '1em',
                                textAlign: 'center',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 'bold',
                                letterSpacing: '0.05em',
                                textTransform: 'uppercase',
                                background: 'linear-gradient(to right, #f06, #48f, #16cc7d, #ff0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: `${rainbowAnimation} 10s ease infinite`,
                                backgroundSize: '200% 200%',
                                '@media (max-width:600px)': {
                                    fontSize: '1.2em',
                                },
                            }}
                        >
                            Wagzters
                        </Typography>
                        <TextField
                            sx={{
                                marginRight: '1em',
                                color: '#333333',
                                '& .MuiInputBase-input': {
                                    color: '#333333' // This line changes the color of the text the user types
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    color: '#333333'
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#16cc7d',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#16cc7d',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#15a2fa',
                                    },
                                },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder="Search"
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={{
                                            color: '#333333'
                                        }}
                                    >
                                        <SearchIcon
                                            sx={{
                                                color: '#333333'
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Box>

                    <Avatar
                        alt="Wagzters Logo"
                        src="/apple-touch-icon.png"
                        sx={{
                            width: 56*1.3,
                            height: 56*1.3,
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            border: '3px solid white'
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <IconButton
                            component="a"
                            href="https://www.pinterest.com/wagzters/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="shareButton"
                        >
                            <FontAwesomeIcon icon={faPinterest} color="#E72B1C" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://twitter.com/wagzters"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="shareButton"
                        >
                            <FontAwesomeIcon icon={faXTwitter} color="#333333" />
                        </IconButton>
                        {/*<IconButton component="a" href="https://www.instagram.com/wagzters/" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <FontAwesomeIcon icon={faInstagram} color="#E1306C" />*/}
                        {/*</IconButton>*/}
                        <IconButton
                            component="a"
                            href="https://www.facebook.com/wagzters/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="shareButton"
                        >
                            <FontAwesomeIcon icon={faFacebook} color="#15a2fa" />
                        </IconButton>
                        {/*<IconButton component="a" href="https://www.tiktok.com/@wagzters" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <FontAwesomeIcon icon={faTiktok} color="#333333" />*/}
                        {/*</IconButton>*/}
                        {/*<IconButton component="a" href="https://www.youtube.com/@Wagzters" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <FontAwesomeIcon icon={faYoutube} color="#FF0000" />*/}
                        {/*</IconButton>*/}
                    </Box>
                </Toolbar>
            </AppBar>



            <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
                <Drawer
                    variant="persistent"
                    open={drawerOpen}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            top: '68px', // AppBar height on desktop devices
                            height: 'calc(100% - 68px)',
                            backgroundColor: '#e6e6e6'
                        },
                        '& .MuiListItemText-root': { // <-- this targets the main text
                            color: '#333333'
                        },
                        '& .MuiListItem-button:hover .MuiListItemText-root': { // <-- this preserves hover effect
                            color: '#49c2f8', // slightly faded color on hover for effect; adjust as desired
                        }
                    }}
                >
                    <List>
                        {drawerItems.map((item, index) => (
                            <ListItem button key={index} component={RouterLink} to={item.route}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                        {!isAuthenticated ? (
                            <ListItem button onClick={loginWithRedirect}>
                                <ListItemIcon>
                                    <LockOpenIcon style={{ color: '#ed6c02' }} />
                                </ListItemIcon>
                                <ListItemText primary="Login" />
                            </ListItem>
                        ) : (
                            <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
                                <ListItemIcon>
                                    <ExitToAppIcon style={{ color: '#ed6c02' }} />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        )}
                    </List>
                </Drawer>


            </Box>
            <Box component="main"
                 sx={{ flexGrow: 1, p: 3, ml: { md: drawerWidth }, mt: { xs: '56px', sm: '64px' } }}
            >
                {/* your main content goes here */}
            </Box>
        </>
    );
};

export default NavBar;
