const countCommentLikes = async (commentId) => {
    try {
        const response = await fetch('/.netlify/functions/countCommentLikes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ commentId }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        const data = await response.json();

        return data.likesCount;

    } catch (error) {
        console.error('Error counting comment likes:', error);
        return 0; // Return 0 on error
    }
};

export default countCommentLikes;
