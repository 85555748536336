/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';
import '../bootstrap-5.2.3-dist/css/bootstrap.min.css';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, Container, TextField, Button, Card, CardContent, FormControl } from '@mui/material';
import {Helmet} from "react-helmet";

const Feedback = () => {
    const { user } = useAuth0();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
    const [feedbackComment, setFeedbackComment] = useState('');
    const [feedbackRating, setFeedbackRating] = useState(0);

    const handleCreateFeedback = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/.netlify/functions/createFeedback`, {
                comment: feedbackComment,
                email: user.email,
                rating: feedbackRating
            });

            setFeedbackComment('');
            setFeedbackRating(0);
        } catch (error) {
            console.error('Error creating feedback', error);
        }
    };

    return (
        <Container maxWidth="xs">

            <Helmet>
                <title>Wagzters - Feedback</title>
                <meta name="description" content="Submit your feedback about Wagzters. Your opinion helps us improve our platform, making it a better place for all pet lovers."/>
                <meta property="og:title" content="Wagzters - Feedback" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://wagzters.com/feedback" />
                <meta property="og:description" content="Submit your feedback about Wagzters. Your opinion helps us improve our platform, making it a better place for all pet lovers." />
            </Helmet>


            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <Card sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#f2f2f2',
                    borderRadius: '15px',
                    border: '2px solid #FFFFFF00'
                }}>
                    <CardContent>
                        <h2 style={{
                            color:'#15a2fa'
                        }}>Feedback</h2>
                        <form>
                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="feedbackComment"
                                    label="Your feedback"
                                    value={feedbackComment}
                                    onChange={(e) => setFeedbackComment(e.target.value)}
                                    multiline
                                    rows={4}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#1c2734',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#16cc7d',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#15a2fa',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#333333'
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333333',
                                        }
                                    }}
                                />

                            </FormControl>

                            <FormControl
                                fullWidth
                                margin="normal"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1c2734',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#16cc7d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#15a2fa',
                                        }
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#9ca3af'
                                    }
                                }}
                            >
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="feedbackRating"
                                    label="Rating (1-5)"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            max: 5,
                                            min: 1
                                        },
                                        sx: {
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#1c2734',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#16cc7d',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#15a2fa',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                color: '#333333'
                                            }
                                        }
                                    }}
                                    value={feedbackRating}
                                    onChange={(e) => setFeedbackRating(e.target.value)}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            color: '#333333',
                                        }
                                    }}
                                />
                            </FormControl>

                            <Button
                                // fullWidth
                                width="50%"
                                type="button"
                                variant="contained"
                                onClick={handleCreateFeedback}
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#15a2fa',
                                    '&:hover': {
                                        backgroundColor: '#FF005C',
                                    },
                                }}
                            >
                                Submit Feedback
                            </Button>
                        </form>

                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default withAuthenticationRequired(Feedback, {
    onRedirecting: () => <div>Loading...</div>,
});
